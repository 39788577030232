import { QuestionType } from "@trunk-tools/txt-shared";
import { UsefulnessVote } from "./UsefulnessVote.component";
import { IoArrowRedo } from "react-icons/io5";
import {
  TileList,
  MarkdownFormatter,
  Button,
  ToastRenderer,
  toast,
} from "@trunk-tools/ui";
import { FaRedo } from "react-icons/fa";
import { useProjectConversationQuestion } from "@/dataHooks/conversations.dataHook";
import { useIdNavigate } from "@/hooks/useIdNavigate";
import { useParams } from "react-router-dom";
import { DocumentTile } from "./DocumentTile.component";
import { AnsweredQuestionConversationItem } from "./ConversationItems/AnsweredQuestionConversationItem.component";
import { QuestionThinkingConversationItem } from "./ConversationItems/QuestionThinkingConversationItem.component";
import { AnswerErrorConversationItem } from "./ConversationItems/AnswerErrorConversationItem.component";
import { AgentAnsweredQuestionConversationItem } from "./ConversationItems/AgentAnsweredQuestionConversationItem.component";

const ConversationItemWrapper = ({
  children,
  embeddedQuestion,
  last,
}: {
  children: React.ReactNode;
  embeddedQuestion: boolean;
  question_text: string;
  last?: boolean;
}) =>
  embeddedQuestion ? (
    <AgentAnsweredQuestionConversationItem
      partOfPreviousItem={true}
      isLastItem={last}
    >
      {children}
    </AgentAnsweredQuestionConversationItem>
  ) : (
    <AnsweredQuestionConversationItem>
      {children}
    </AnsweredQuestionConversationItem>
  );
export const AnswerMessage = ({
  question,
  embeddedQuestion,
  index,
  last,
}: {
  index: number;
  embeddedQuestion?: boolean;
  last?: boolean;
  question: QuestionType & {
    question_documents: Array<{
      id: string;
      document: { id: string };
    }>;
  };
}) => {
  const { regenerateResponse } = useProjectConversationQuestion();
  const idNavigate = useIdNavigate();
  const { conversationId } = useParams<{ conversationId: string }>();

  switch (question.state) {
    case "ANSWERED": {
      // Sometimes we get strings back like "<ANSWER-WE-GENERATED> SOURCES: s-0, s-1, s-4, s-10" where
      // the sources that the LLM used are listed at the end. And sometimes it's in Spanish too if the
      // whole answer was requested to be in Spanish.
      const answerText = question.answer_text
        ?.replaceAll(/SOURCES: /g, "")
        .replaceAll(/FUENTES: /g, "") // Spanish for "SOURCES"
        .replaceAll(/s-[0-9]*,?/g, "")
        .trim();

      return (
        <ConversationItemWrapper
          embeddedQuestion={embeddedQuestion || false}
          question_text={question.question_text}
          last={last}
        >
          <div className="flex flex-col gap-y-6">
            {embeddedQuestion && (
              <div className="h-[86px] p-4 bg-[#364651]/30 rounded-lg border border-[#12191d] flex-col justify-start items-start gap-4 inline-flex">
                <div className="self-stretch text-white text-[10px] font-normal font-['Izoard Soft'] leading-tight">
                  QUESTION {`${index + 1}`}
                </div>
                <div className="self-stretch text-white text-xs font-normal font-['Poppins'] leading-[18px]">
                  {question.question_text}
                </div>
              </div>
            )}
            <MarkdownFormatter>{answerText}</MarkdownFormatter>
            {/** The height here helps make sure the UI doesn't flicker and update when the content below is shown on desktop */}
            <div className={`w-full desktop:max-h-full desktop:h-[50px]`}>
              <div
                className={`flex flex-col-reverse gap-y-6 gap-x-2 items-center justify-between w-full desktop:flex desktop:flex-row`}
              >
                <div
                  className="w-full desktop:w-fit"
                  data-pendo-id="copy-response-to-clipboard"
                >
                  <ToastRenderer />
                  <Button
                    size="sm"
                    onClick={async () => {
                      await navigator.clipboard.writeText(
                        `Question: ${question.question_text}\n\nAnswer: ${answerText}`,
                      );
                      toast.success(
                        "Response successfully copied to clipboard. Paste it in a text message or email to share this page with a colleague.",
                      );
                    }}
                  >
                    <IoArrowRedo className="w-5 h-5 rotate-180" />
                    <p className="uppercase tracking-normal text-xs">
                      copy response to clipboard
                    </p>
                  </Button>
                </div>
                <div className="flex flex-row gap-x-2 items-center justify-between">
                  <div className="uppercase text-xs">
                    did this response help you find your answer?
                  </div>
                  <UsefulnessVote
                    itemId={question.id}
                    itemType={"QUESTION_ANSWER"}
                  />
                </div>
              </div>
            </div>
            {question.question_documents.length > 0 && (
              <div className="flex flex-col gap-y-2">
                <div className="text-xs uppercase">Response Sources</div>
                <TileList
                  tiles={question.question_documents.map((qd) => (
                    <DocumentTile
                      showSubContent={true}
                      questionId={question.id}
                      documentId={qd.document.id}
                      itemId={qd.id}
                      itemType="QUESTION_ANSWER_DOCUMENT"
                      onClick={() => {
                        idNavigate(
                          `/documents/${qd.document.id}?questionId=${question.id}&conversationId=${conversationId}`,
                        );
                      }}
                    />
                  ))}
                />
              </div>
            )}
          </div>
        </ConversationItemWrapper>
      );
    }
    case "CREATED":
    case "THINKING":
      return (
        <QuestionThinkingConversationItem
          embeddedQuestion={
            question.originator === "AGENT" ? question.question_text : undefined
          }
          index={index}
        />
      );
    case "ERRORED":
    case "FAILED_TO_ANSWER":
      return (
        <AnswerErrorConversationItem>
          {embeddedQuestion && (
            <div className="h-[86px] p-4 bg-[#364651]/30 rounded-lg border border-[#12191d] flex-col justify-start items-start gap-4 inline-flex">
              <div className="self-stretch text-white text-[10px] font-normal font-['Izoard Soft'] leading-tight">
                QUESTION {`${index + 1}`}
              </div>
              <div className="self-stretch text-white text-xs font-normal font-['Poppins'] leading-[18px]">
                {question.question_text}
              </div>
            </div>
          )}
          <div className="flex flex-col gap-y-5 font-poppins text-lg tracking-normal">
            Sorry, Trunk Text encountered an error.
            <div>
              <Button
                size="md"
                variant="secondary"
                isInline
                icon={FaRedo}
                onClick={() =>
                  regenerateResponse({
                    conversationId: question.conversation_id,
                    questionId: question.id,
                  })
                }
              >
                Regenerate Response
              </Button>
            </div>
          </div>
        </AnswerErrorConversationItem>
      );
    default:
      // This will be a compile-time error if we forget to handle a state
      // eslint-disable-next-line no-case-declarations, @typescript-eslint/no-unused-vars
      const _exhaustiveCheck: never = question.state;
  }
};
