import plusWhite from "public/plus_white.svg";
import plusGrey from "public/plus_grey.svg";
import { ConversationsList } from "@/components/Conversations/ConversationsList.component";
import { UserIconMenu } from "@/components/Conversations/UserIconMenu.component";
import { ProjectPicker } from "@/components/ProjectPicker";
import {
  Button,
  LoadingChevron,
  LoadingCircle,
  LoadingRectangle,
} from "@trunk-tools/ui";
import { useIdNavigate } from "@/hooks/useIdNavigate";
import { Suspense } from "react";
import { useCurrentProject } from "dataHooks";

const SuspenseBoundary = ({ children }: { children: React.ReactNode }) => {
  return (
    <Suspense
      fallback={
        <div className="flex flex-col h-full">
          <LoadingRectangle width="w-full" height="h-14" />
          <div className="w-full mt-12">
            <LoadingRectangle width="w-full" height="h-14" />
          </div>
          <div className="w-full mt-6">
            <LoadingRectangle width="w-full" height="h-14" />
          </div>
          <div className="mt-6">
            <LoadingRectangle width="w-15" height="h-5" />
          </div>
          <div className="mt-6 ml-4">
            <LoadingRectangle width="w-56" height="h-5" />
          </div>
          <div className="mt-6">
            <LoadingRectangle width="w-17" height="h-5" />
          </div>
          {[1, 2, 3, 4, 5].map((n) => (
            <div className="mt-6 ml-4">
              <LoadingRectangle width="w-40" height="h-5" key={n} />
            </div>
          ))}
          <div className="flex flex-row mt-auto items-center">
            <LoadingCircle />
            <div className="flex flex-col">
              <div className="ml-4">
                <LoadingRectangle width="w-20" height="h-4" />
                <div className="mt-2">
                  <LoadingRectangle width="w-30" height="h-4" />
                </div>
              </div>
            </div>
            <div className="ml-auto">
              <LoadingChevron />
            </div>
          </div>
        </div>
      }
    >
      {children}
    </Suspense>
  );
};

type AskPageSidebarProps = {
  setSidebarOpen: (arg: boolean) => void;
};

const AskPageSidebarInner = ({ setSidebarOpen }: AskPageSidebarProps) => {
  const navigate = useIdNavigate();
  const { currentProject } = useCurrentProject();

  const newConversationButton =
    currentProject.project_question_status === "READY_FOR_QUESTIONS" ? (
      <Button
        variant="success"
        onClick={() => {
          navigate("/conversations/new");
          setSidebarOpen(false);
        }}
      >
        <img src={plusWhite} alt="new conversation plus" className="w-5 h-5" />
        <div className="text-sm">NEW CONVERSATION</div>
      </Button>
    ) : (
      <div
        className="w-full h-14 flex flex-row gap-x-3 justify-center items-center rounded bg-[#2931437A]"
        style={{
          boxShadow: "0px 1px 0px 0px #FFFFFF14",
          border: "2px solid #12151D",
        }}
      >
        <img src={plusGrey} alt="new conversation plus" className="w-5 h-5" />
        <div className="text-sm text-[#171717]">NEW CONVERSATION</div>
      </div>
    );

  const conversationsList =
    currentProject.project_question_status === "READY_FOR_QUESTIONS" ? (
      <ConversationsList setSidebarOpen={setSidebarOpen} />
    ) : null;

  return (
    <div className="flex flex-col gap-y-6 h-full max-h-full">
      <ProjectPicker />
      <span data-pendo-id="new-conversation-button">
        {newConversationButton}
      </span>
      {conversationsList}
      <div className="mt-auto">
        <UserIconMenu />
      </div>
    </div>
  );
};

export const AskPageSidebar = ({ setSidebarOpen }: AskPageSidebarProps) => {
  return (
    <SuspenseBoundary>
      <AskPageSidebarInner setSidebarOpen={setSidebarOpen} />
    </SuspenseBoundary>
  );
};
