import { useDocument } from "@/dataHooks/documents.dataHook";
import { Button, ExpandableTile } from "@trunk-tools/ui";
import csvFileIcon from "public/files/brand/file-CSV.svg";
import docFileIcon from "public/files/brand/file-DOC.svg";
import genericFileIcon from "public/files/brand/file-generic.svg";
import pdfFileIcon from "public/files/brand/file-PDF.svg";
import xerFileIcon from "public/files/brand/file-XER.svg";
import xlsFileIcon from "public/files/brand/file-XLS.svg";
import { UsefulnessVote } from "./UsefulnessVote.component";
import { UsefulnessVoteProps, useQuestionDocument } from "dataHooks";
import { useState } from "react";
import { download } from "@/utils/download";
import { GrDocumentDownload } from "react-icons/gr";

interface MainContentProps {
  fileType: string;
  filename: string;
  fileFolder: string;
  fileInformation: string;
  onClick?: () => void;
}

const DocumentTileMainContent = ({
  fileType,
  filename,
  fileFolder,
  fileInformation,
  onClick,
}: MainContentProps) => {
  let fileIcon: string;

  switch (fileType) {
    case "pdf":
      fileIcon = pdfFileIcon;
      break;
    case "doc":
    case "docx":
      fileIcon = docFileIcon;
      break;
    case "xls":
    case "xlsx":
      fileIcon = xlsFileIcon;
      break;
    case "csv":
      fileIcon = csvFileIcon;
      break;
    case "xer":
      fileIcon = xerFileIcon;
      break;
    default:
      fileIcon = genericFileIcon;
      break;
  }

  return (
    <div className="flex flex-row gap-x-4" onClick={onClick}>
      <div>
        <img
          className="min-w-[30px] min-h-[32px]"
          src={fileIcon}
          alt="file icon"
        />
      </div>
      <div className="flex flex-col gap-y-1">
        <div
          className={`${
            onClick !== undefined ? "text-brand underline cursor-pointer" : ""
          }`}
        >
          {filename.trim().length > 0
            ? `${fileFolder}/${filename}`
            : "Untitled"}
        </div>
        <div>{fileInformation}</div>
      </div>
    </div>
  );
};

const DocumentTileSubContent = ({
  itemId,
  itemType,
  documentId,
  questionId,
}: {
  documentId: string;
  questionId: string;
} & UsefulnessVoteProps) => {
  const { document } = useDocument({ documentId });
  const [waitingForDownload, setWaitingForDownload] = useState(false);
  const { url } = useQuestionDocument({ questionId, documentId });

  return (
    <div className="h-full flex flex-row items-center gap-x-6">
      <div className="uppercase desktop:hidden">
        did this doc help you find your answer?
      </div>
      <UsefulnessVote itemId={itemId} itemType={itemType} />
      <div className="hidden desktop:block" data-pendo-id="document-download">
        {url ? (
          <Button
            icon={GrDocumentDownload}
            variant="primary"
            size="sm"
            loading={waitingForDownload}
            onClick={async () => {
              setWaitingForDownload(true);
              download(url, document.name);
              setWaitingForDownload(false);
            }}
          >
            Download
          </Button>
        ) : (
          <Button variant="error" size="sm">
            Can't Download
          </Button>
        )}
      </div>
    </div>
  );
};

// Discriminated union on the `showSubContent` property
type DocumentTileProps = (
  | {
      showSubContent: false;
    }
  | ({
      showSubContent: true;
      questionId: string;
    } & UsefulnessVoteProps)
) & { documentId: string; onClick?: () => void };

export const DocumentTile = (props: DocumentTileProps) => {
  const { documentId, onClick, showSubContent } = props;

  const { document } = useDocument({ documentId: props.documentId });

  return (
    <ExpandableTile
      mainContent={
        <DocumentTileMainContent
          fileInformation={`${document.type}${
            document.source ? ` | ${document.source}` : ""
          }`}
          fileType={document.name.split(".").pop() || ""}
          fileFolder={document.folder}
          filename={document.name}
          onClick={onClick}
        />
      }
      subContent={
        showSubContent ? (
          <DocumentTileSubContent
            itemId={props.itemId}
            itemType={props.itemType}
            documentId={documentId}
            questionId={props.questionId}
          />
        ) : null
      }
    />
  );
};
