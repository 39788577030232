import { useUsefulnessVote, UsefulnessVoteProps } from "dataHooks";
import { FaThumbsUp } from "react-icons/fa";
import { FaThumbsDown } from "react-icons/fa";
import { tw } from "@trunk-tools/ui";

export const UsefulnessVote = ({ itemType, itemId }: UsefulnessVoteProps) => {
  const { votePositive, voteNegative, currentVote } = useUsefulnessVote({
    itemType,
    itemId,
  });

  return (
    <div
      onClick={(event) => event.stopPropagation()}
      className="flex justify-between"
    >
      <div className="flex gap-3">
        <FaThumbsDown
          data-pendo-id={`usefulness-vote-negative-${itemType}`}
          onClick={voteNegative}
          className={tw(
            "w-[16px] h-[16px] hover:scale-110 fill-brand top-[4px] relative cursor-pointer",
            {
              value: typeof currentVote === "undefined",
              true: "fill-white",
            },
            {
              value: currentVote?.vote === "NEGATIVE",
              true: "fill-brand",
            },
            {
              value: currentVote?.vote === "POSITIVE",
              true: "fill-black",
            },
          )}
        />
        <FaThumbsUp
          data-pendo-id={`usefulness-vote-positive-${itemType}`}
          onClick={votePositive}
          className={tw(
            "w-[16px] h-[16px] hover:scale-110 cursor-pointer",
            {
              value: typeof currentVote === "undefined",
              true: "fill-white",
            },
            {
              value: currentVote?.vote === "NEGATIVE",
              true: "fill-black",
            },
            {
              value: currentVote?.vote === "POSITIVE",
              true: "fill-brand",
            },
          )}
        />
      </div>
    </div>
  );
};
